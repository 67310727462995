<template>
  <div>
    <div class="program-hero" v-if="upcomingProgramsList.length">
      <div @click="leftRotateArr" class="hero-icon hero-icon--up">
        <unicon name="angle-up" />
      </div>
      <div @click="rightRotateArr" class="hero-icon hero-icon--down">
        <unicon name="angle-down" />
      </div>
      <div class="program-hero__background">
        <img src="@/assets/mellekh.svg" alt="" v-if="upcomingPrograms.length" />
      </div>
      <div class="program-hero-container">
        <transition name="hero-card-fade" mode="out-in">
          <UpcomingProgram
            :upComingProgram="upcomingProgramsList[upcomingPrograms.length - 1]"
            :cardsSlideUp="cardsSlideUp"
            v-on:moreDetailClickHandler="upComingProgramCardClickHandler"
            v-on:pauseInterval="pauseInterval"
            v-on:resumeInterval="resumeInterval"
          />
        </transition>
      </div>
    </div>
    <div class="program-list" v-if="upcomingProgramsList.length">
      <transition-group
        :name="cardsSlideUp ? 'flip-list-up' : 'flip-list-down'"
      >
        <UpcomingProgram
          v-for="upComingProgram in upcomingProgramsList"
          :key="upComingProgram['s.no.']"
          :upComingProgram="upComingProgram"
          v-on:upComingProgramCardClickHandler="upComingProgramCardClickHandler"
          v-on:pauseInterval="pauseInterval"
          v-on:resumeInterval="resumeInterval"
        />
      </transition-group>
    </div>
    <div class="program-list__no-data" v-else>
      <NoDataFound />
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      upcomingProgramsList: [],
      interval: "",
      cardsSlideUp: true
    };
  },
  computed: {
    upcomingPrograms() {
      return this.$store.getters.upcomingPrograms;
    }
  },
  created() {
    this.autoRotateArr();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  components: {
    UpcomingProgram: () => import("./upcomingProgram.vue")
  },
  watch: {
    upcomingPrograms: {
      handler: function () {
        this.upcomingProgramsList = this.$store.getters.upcomingPrograms;
      },
      immediate: true
    }
  },
  methods: {
    rightRotateArr() {
      // stop interval timer
      clearInterval(this.interval);
      // carousel slide up
      this.cardsSlideUp = false;
      // right rotate array
      let el = this.upcomingProgramsList.pop();
      setTimeout(() => this.upcomingProgramsList.unshift(el), 300);
      // restart the interval timer
      this.setIntervalForLeftRotation();
    },
    leftRotateArr() {
      // stop interval timer
      clearInterval(this.interval);
      this.cardsSlideUp = true;
      if (this.upcomingProgramsList.length !== 0) {
        let el = this.upcomingProgramsList.shift();
        setTimeout(() => this.upcomingProgramsList.push(el), 300);
      }
      // restart the interval timer
      this.setIntervalForLeftRotation();
    },
    autoRotateArr() {
      this.setIntervalForLeftRotation();
    },
    upComingProgramCardClickHandler(id) {
      this.$router.push({
        name: "upcoming-program-detail",
        params: { upcomingProgramId: id }
      });
    },
    setIntervalForLeftRotation() {
      this.interval = setInterval(() => {
        if (!this.isIntervalPaused) {
          this.leftRotateArr();
        }
      }, 10000);
    },
    pauseInterval() {
      this.isIntervalPaused = true;
    },
    resumeInterval() {
      this.isIntervalPaused = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
